import Pages from 'common/src/app/data/enum/Pages';
import PagesExternal from 'common/src/app/data/enum/PagesExternal';
import salesforceFaqsLink from 'common/src/app/util/salesforceFaqsLink';
import marketConfig from 'common/src/app/config/market/market.configdefinitions';
import { createPath } from 'common/src/app/util/routeUtils';

export const UkPublicLinkCategories = {
  MARKETING: 'marketing',
  CONTACTPOLICIES: 'contact',
  OTHERAUDIENCES: 'other',
};

export const footerLinks = (host, marketName) => [
  [
    {
      title: 'Losing weight with us',
      content: {
        [Pages.UK_PUBLIC_NEAREST_GROUP_LANDING]: {
          title: 'Find a group',
        },
        [Pages.UK_PUBLIC_NEAREST_GROUP_COUNTIES]: {
          title: 'Browse our groups',
        },
        [Pages.UK_PUBLIC_WHAT_HAPPENS_IN_GROUP]: {
          title: 'What happens in group',
        },
        [Pages.UK_PUBLIC_ONLINE_INFORMATION]: {
          title: 'Our digital service',
        },
        [Pages.UK_PUBLIC_SEVEN_DAY_MENU_ENTRY]: {
          title: 'Free 7-day meal plan',
        },
        [Pages.UK_PUBLIC_SLIMMING_WORLD_APP]: {
          title: 'The Slimming World App',
        },
        [Pages.UK_PUBLIC_MYTHS]: {
          title: 'Your burning questions answered',
        },
        [Pages.UK_PUBLIC_OFFERS]: {
          title: 'Offers',
        },
        [Pages.UK_PUBLIC_BODY_MAGIC]: {
          title: 'Our physical activity programme',
        },
        [Pages.UK_PUBLIC_SCIENCE]: {
          title: 'Slimming World science',
        },
        [Pages.UK_PUBLIC_ICELAND]: {
          title: 'Slimming World food range',
        },
        ...(marketName === 'UK'
          ? {
              [PagesExternal.SW_KITCHEN]: {
                title: 'Slimming World Kitchen',
                target: '_new',
              },
            }
          : {}),
        [Pages.UK_PUBLIC_BMI_CALCULATOR]: {
          title: 'BMI calculator',
        },
        [Pages.UK_PUBLIC_COMPARISON_PAGE]: {
          title: 'Slimming World or Weight Watchers',
        },
      },
    },
  ],
  [
    {
      title: 'Company Information',
      content: {
        [Pages.UK_PUBLIC_OUR_STORY]: {
          title: 'About us',
        },
        [Pages.UK_PUBLIC_ADVERTISE_WITH_US]: {
          title: 'Advertise in our magazine',
        },
        [PagesExternal.ADVERTISING_ARTWORK]: {
          title: 'Advertising artwork',
          target: '_new',
        },
        [Pages.UK_PUBLIC_CAREERS]: {
          title: 'Careers',
          target: '_new',
        },
        [Pages.UK_PUBLIC_BECOME_A_CONSULTANT]: {
          title: 'Become a Consultant',
        },
        [PagesExternal.NEWSROOM]: {
          title: 'Newsroom',
          target: '_new',
        },
      },
    },
  ],
  [
    {
      title: 'Customer Support',
      content: {
        [createPath(Pages.CONTACT_US)]: {
          title: 'Contact us',
        },
        [salesforceFaqsLink(host)]: {
          title: 'Slimming World FAQs',
          target: '_new',
        },
      },
    },
    {
      title: 'More from us',
      content: {
        [Pages.BLOG]: {
          title: 'The Slimming World Blog',
          target: '_new',
        },
        [Pages.UK_PUBLIC_PODCASTS]: {
          title: 'Slimming World podcast',
        },
        [marketConfig.pregnancyCTAUrl]: {
          title: 'Pregnant or new mum?',
          target: '_new',
        },
        [PagesExternal.HEALTH_LEGACY_URL]: {
          title: 'Health professionals',
          target: '_new',
        },
      },
    },
  ],
];

export const footerSubLinks = {
  [Pages.TERMS_OF_USE]: 'Terms and conditions',
  [Pages.PRIVACYPOLICY]: 'Privacy promise',
  [Pages.COOKIESPOLICY]: 'Cookies',
  [Pages.ACCESSIBILITYPOLICY]: 'Accessibility',
  [Pages.MODERNSLAVERYACT]: 'Modern Slavery Act',
};

// header
const ukPublicLinks = [
  {
    locale: 'home',
    to: Pages.HOME,
  },
  {
    locale: 'howItWorks',
    to: Pages.UK_PUBLIC_HOW_IT_WORKS,
  },
  {
    locale: 'whatCanIEat',
    to: Pages.UK_PUBLIC_WHAT_CAN_I_EAT,
  },
  {
    locale: 'recipies',
    to: Pages.UK_PUBLIC_RECIPE_SEARCH,
  },
  {
    locale: 'successStories',
    to: Pages.UK_PUBLIC_SUCCESS_STORIES,
  },
  {
    locale: 'specialOffers',
    to: Pages.UK_PUBLIC_OFFERS,
  },
  {
    locale: 'ourStory',
    to: Pages.UK_PUBLIC_OUR_STORY,
  },
  {
    locale: 'blog',
    href: Pages.BLOG,
  },
];

export default ukPublicLinks;
