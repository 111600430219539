import { connect } from 'react-redux';
import compose from 'redux/lib/compose';
import formValueSelector from 'redux-form/lib/formValueSelector';
import { openModal } from 'common/src/app/actions/components/modalActions';
import FormNames from 'common/src/app/data/enum/FormNames';
import RecipeInstructions from 'common/src/app/data/enum/RecipeInstructions';
import { setRecipeModalOpen } from 'common/src/app/actions/resources/plannerRecipeActions';
import RecipeInstructionFields from 'common/src/app/data/enum/FieldNames/RecipeInstructionFields';
import RecipeDetailContent from './RecipeDetailContent';

const mapStateToProps = () => {
  const form = formValueSelector(FormNames.RECIPE_INSTRUCTION_SELECTION);

  return state => {
    const selectedInstruction = form(state, RecipeInstructionFields.INSTRUCTION);
    return {
      selectedInstruction,
      initialValues: {
        [RecipeInstructionFields.INSTRUCTION]: RecipeInstructions.INGREDIENTS,
      },
    };
  };
};

const mapDispatchToProps = dispatch => ({
  openModal: (...args) => dispatch(openModal(...args)),
  setRecipeModalOpen: isOpen => dispatch(setRecipeModalOpen(isOpen)),
});

const connected = connect(mapStateToProps, mapDispatchToProps);

export default compose(connected)(RecipeDetailContent);
