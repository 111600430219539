import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';
import { convertRecipeFlagsToIconClass } from 'common/src/app/util/content/convertRecipeFlagsToIcon';
import recipeDetailShape from 'common/src/app/util/proptypes/apiEntities/recipeDetail';
import IconName from 'common/src/app/data/enum/IconName';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import RelatedEntity from 'common/src/app/data/enum/RelatedEntityKind';
import InteractionType from 'common/src/app/data/enum/InteractionType';
import InteractionToggleWrapper from 'common/src/app/hoc/InteractionToggleWrapper';
import RecipeInstructions from 'common/src/app/data/enum/RecipeInstructions';
import RecipeInstructionFields from 'common/src/app/data/enum/FieldNames/RecipeInstructionFields';
import VideoType from 'common/src/app/data/enum/VideoType';
import ModalNames from 'common/src/app/data/enum/ModalNames';
import factSheetShape from 'common/src/app/util/proptypes/apiEntities/factSheetShape';
import Icon from '../../atoms/Icon';
import Wrapper from '../../atoms/Wrapper';
import ResponsiveImage from '../../atoms/ResponsiveImage';
import StandoutLabel from '../../molecules/StandoutLabel';
import LocaleMessage from '../../atoms/LocaleMessage';
import TextNew, { Colors, ElementTypes, FontWeight } from '../../atoms/TextNew';
import Button from '../../atoms/Button';
import DiabetesUkBanner from '../../atoms/DiabetesUkBanner';
import { LikeButtonInteraction } from '../../molecules/LikeButton';
import CommentButton from '../../molecules/CommentButton';
import RichTextBlock from '../../molecules/BlockContent/components/atoms/RichTextBlock';
import NutritionSymbols from '../NutritionSymbols';
import NotableDetails from '../NotableDetails';
import FoodRange from '../../molecules/FoodRange';
import RadioButtonGroup from '../../molecules/RadioButtonGroup';
import RadioStyleType from '../../atoms/RadioButton/RadioStyleType';
import RadioButton from '../../atoms/RadioButton';
import PublicRecipeMetaBar from '../../molecules/PublicRecipeMetaBar';
import FoodRangeCta from '../../molecules/FoodRangeCta';
import Tip from '../../molecules/Tip';
import AllergyInfo from '../../molecules/AllergyInfo';
import DiabetesUKNutritionalInformation from '../../molecules/DiabetesUKNutritionalInformation';
import DiabetesUKNutritionalInformationModal from '../../molecules/DiabetesUKNutritionalInformationModal/DiabetesUKNutritionalInformationModal';

import './recipe-detail-content.scss';

/**
 * Recipe detail content: image, description, notable details, ingredients, method
 * Used for the recipe detail page in member, and the recipe detail modal in planner
 */
const RecipeDetailContent = (
  {
    recipe: {
      id,
      title,
      description,
      subdescription,
      characteristics,
      syns,
      totalTime,
      servings,
      healthyExtras,
      synsType,
      likesCount,
      image,
      isFoodRange,
      foodRangeCategoryId,
      tip,
      subSections,
      method,
      ingredients,
      privateVideoId,
      additionalTimeInMinutes,
      additionalTimeSuffix,
      diabetesNutritionalInformation,
    },
    factSheet,
    deviceState,
    showSocialActions,
    onCommentClick,
    openModal,
    videoCloseCallback,
    setRecipeModalOpen,
    brightcoveAccountId,
    brightcovePlayerId,
    trackVideoEvent,
  },
  context,
  className,
) => {
  const symbols = convertRecipeFlagsToIconClass(characteristics);

  const notableDetails = {
    syns,
    totalTime,
    servings,
    synsType,
    likesCount,
    additionalTimeInMinutes,
    additionalTimeSuffix,
  };

  const videoData = {
    _type: VideoType.BRIGHTCOVE,
    videoId: privateVideoId,
    autoplay: 0,
    controls: 2,
    loop: 0,
    muted: 0,
  };

  const openVideoModal = () => {
    videoCloseCallback && setRecipeModalOpen(false);
    openModal(
      ModalNames.VIDEO,
      {
        video: videoData,
        closeCallback: videoCloseCallback || null,
        brightcoveAccountId,
        brightcovePlayerId,
        trackVideoEvent,
      },
      true,
      true,
      true,
    );
  };

  // This check is needed due to how the design of the layout changes between screen sizes
  // added to ensure that when the page is being previewed for print.
  // and for when deviceState is being calculated during print view

  const printRequest = typeof window !== 'undefined' && window.matchMedia('print').matches;

  const showDiabetesNutritionalInformation = diabetesNutritionalInformation?.length > 0;

  const [
    showDiabetesNutritionalInformationpModal,
    setShowDiabetesNutritionalInformationpModal,
  ] = useState(false);

  const [selectedView, setSelectedView] = useState(RecipeInstructions.INGREDIENTS);

  return (
    <div className={className}>
      <div itemScope itemType="http://schema.org/Recipe">
        <Wrapper width="lg" className="recipe-description">
          <figure>
            <div className="image-wrapper">
              {videoData?.videoId && (
                <div className="play-button">
                  <Button isTertiary onClick={openVideoModal}>
                    <Icon name={IconName.PLAY} width={34} height={34} />
                  </Button>
                </div>
              )}
              <ResponsiveImage {...image} px={640} ratio={1} itemProp="image" />
              <NutritionSymbols symbols={symbols} />
            </div>

            {isFoodRange && (
              <StandoutLabel labelText={<LocaleMessage id="general.tiles.foodRange" />} />
            )}

            {showDiabetesNutritionalInformation && <DiabetesUkBanner />}
          </figure>
          <aside>
            <TextNew.Script.SM
              element={ElementTypes.H1}
              cid="recipe-title"
              color={Colors.BRAND}
              itemProp="name"
            >
              {title}
            </TextNew.Script.SM>

            {description && <RichTextBlock cid="description" text={description} />}

            {(deviceState > DeviceState.LG || printRequest) && (
              <NotableDetails
                {...notableDetails}
                healthyExtras={healthyExtras}
                showLikes={showSocialActions}
              />
            )}

            {showSocialActions && (
              <div className="recipe-social-actions">
                <InteractionToggleWrapper
                  targetId={id}
                  targetTitle={title}
                  interactionType={InteractionType.LIKE}
                  targetType={RelatedEntity.RECIPE}
                  component={LikeButtonInteraction}
                />
                {onCommentClick && <CommentButton onClick={onCommentClick} />}
              </div>
            )}

            {isFoodRange && <FoodRange foodRangeCategoryId={foodRangeCategoryId} />}
            {!isFoodRange && tip && <Tip tip={tip} />}
            {isFoodRange && <AllergyInfo factSheet={factSheet} />}

            {showDiabetesNutritionalInformation && (
              <div className="diabetes-uk-cta">
                <Icon width={20} name={IconName.INFORMATION} rounded />

                <Button
                  isImitationLink
                  onClick={() => setShowDiabetesNutritionalInformationpModal(true)}
                >
                  <span className="screen-reader">
                    <LocaleMessage id="recipeDetailContent.diabetesUKModal.screenReader" />
                  </span>

                  <TextNew.Sans.SM
                    color={Colors.BRAND}
                    element={ElementTypes.SPAN}
                    localeId="recipeDetailContent.diabetesUKModal.cta"
                    weight={FontWeight.LIGHT}
                  />
                </Button>

                <DiabetesUKNutritionalInformationModal
                  children={
                    <DiabetesUKNutritionalInformation
                      information={diabetesNutritionalInformation}
                    />
                  }
                  modalIsOpen={showDiabetesNutritionalInformationpModal}
                  setModalIsOpen={setShowDiabetesNutritionalInformationpModal}
                />
              </div>
            )}

            {subdescription && <RichTextBlock cid="sub-description" text={subdescription} />}
          </aside>
        </Wrapper>

        {deviceState <= DeviceState.LG && !printRequest && (
          <NotableDetails
            {...notableDetails}
            healthyExtras={healthyExtras}
            showLikes={showSocialActions}
          />
        )}

        <div className="instructions">
          {deviceState < DeviceState.LG && (
            <form autoComplete="off" noValidate>
              <RadioButtonGroup
                input={{
                  value: selectedView,
                  onChange: setSelectedView,
                  name: RecipeInstructionFields.INSTRUCTION,
                }}
                type={RadioStyleType.PILL}
              >
                <RadioButton
                  label={<LocaleMessage id="recipeDetailContent.ingredients" />}
                  value={RecipeInstructions.INGREDIENTS}
                />
                <RadioButton
                  label={<LocaleMessage id="recipeDetailContent.method" />}
                  value={RecipeInstructions.METHOD}
                />
              </RadioButtonGroup>
            </form>
          )}
          <Wrapper width="lg" padding="xl">
            {(deviceState >= DeviceState.LG ||
              selectedView === RecipeInstructions.INGREDIENTS ||
              printRequest) && (
              <div className="ingredients">
                {(deviceState >= DeviceState.LG || printRequest) && (
                  <TextNew.Script.XS
                    element={ElementTypes.H2}
                    color={Colors.BRAND}
                    localeId="recipeDetailContent.ingredients"
                  />
                )}
                <ul>
                  {ingredients &&
                    ingredients.map((ingredient, index) => (
                      <TextNew.PrimaryElegant key={index} element={ElementTypes.LI}>
                        <RichTextBlock text={ingredient} itemProp="recipeIngredient" />
                      </TextNew.PrimaryElegant>
                    ))}
                </ul>

                <div className="sub-sections">
                  {subSections &&
                    subSections.map(subSection => (
                      <div key={subSection.title}>
                        <TextNew.Script.XS element={ElementTypes.H2} color={Colors.BRAND}>
                          {subSection.title}
                        </TextNew.Script.XS>
                        <ul>
                          {subSection.ingredients &&
                            subSection.ingredients.map((ingredient, index) => (
                              <TextNew.PrimaryElegant key={index} element={ElementTypes.LI}>
                                <RichTextBlock text={ingredient} itemProp="recipeIngredient" />
                              </TextNew.PrimaryElegant>
                            ))}
                        </ul>
                      </div>
                    ))}
                </div>
              </div>
            )}
            {(deviceState >= DeviceState.LG ||
              selectedView === RecipeInstructions.METHOD ||
              printRequest) && (
              <div className="method">
                {(deviceState >= DeviceState.LG || printRequest) && (
                  <TextNew.Script.XS
                    element={ElementTypes.H2}
                    color={Colors.BRAND}
                    localeId="recipeDetailContent.method"
                  />
                )}
                <ol>
                  {method &&
                    method.map(step => (
                      <TextNew.PrimaryElegant key={step} element={ElementTypes.LI}>
                        <span
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{ __html: step }}
                          itemProp="step"
                        />
                      </TextNew.PrimaryElegant>
                    ))}
                </ol>
              </div>
            )}
          </Wrapper>
        </div>

        {showDiabetesNutritionalInformation && (
          <div className="diabetes-nutritional-information-print-view">
            <Icon width={20} name={IconName.INFORMATION} rounded />
            <DiabetesUKNutritionalInformation information={diabetesNutritionalInformation} />
          </div>
        )}
      </div>

      <Wrapper width="lg" padding="xl">
        <PublicRecipeMetaBar image={image.src} />
      </Wrapper>

      {isFoodRange && <FoodRangeCta foodRangeCategoryId={foodRangeCategoryId} />}
    </div>
  );
};

RecipeDetailContent.defaultProps = {
  isInRecipeModal: false,
};

RecipeDetailContent.propTypes = {
  /**
   * Standard device state for JS media query
   */
  deviceState: PropTypes.number,
  /**
   * The recipe to render
   */
  recipe: PropTypes.shape(recipeDetailShape),
  /**
   * Boolean to show or hide comment and like button
   */
  showSocialActions: PropTypes.bool,
  /**
   * Function to scroll to the comments section on recipe detail page
   */
  onCommentClick: PropTypes.func,
  /**
   * Open modal
   */
  openModal: PropTypes.func,
  /**
   * Set recipe modal state
   */
  setRecipeModalOpen: PropTypes.func,
  /**
   * Callback to be executed when a video modal, opened from a recipe modal, is closed (re-opens recipe modal)
   */
  videoCloseCallback: PropTypes.func,
  /**
   * Optional dietary info
   */
  factSheet: PropTypes.shape(factSheetShape),
  brightcoveAccountId: PropTypes.string,
  brightcovePlayerId: PropTypes.string,
  /**
   * Action to expose video information to the data layer
   */
  trackVideoEvent: PropTypes.func,
};

export default withDeviceState()(
  withFunctionalClassName(ComponentType.ORGANISM, 'RecipeDetailContent')(RecipeDetailContent),
);
