import React from 'react';
import PropTypes from 'prop-types';
import TextNew, { Colors, ElementTypes } from 'components/atoms/TextNew';
import VideoType from 'common/src/app/data/enum/VideoType';
import ImagePropTypes from 'common/src/app/util/proptypes/ImagePropTypes';
import Link from 'react-router/lib/Link';
import Button from 'components/atoms/Button';
import Pages from 'common/src/app/data/enum/Pages';
import ResponsiveImage from 'components/atoms/ResponsiveImage';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import VideoBlock from 'components/molecules/BlockContent/components/atoms/VideoBlock';
import PagesExternal from 'common/src/app/data/enum/PagesExternal';
import ComponentType from 'common/src/app/data/enum/ComponentType';

import './homepage-secondary-showcase.scss';

const HomepageSecondaryShowcase = ({ buttonText, video, image, richText }, context, className) => {
  const buttonLink = video
    ? { to: Pages.UK_PUBLIC_NEAREST_GROUP_LANDING }
    : { href: PagesExternal.SW_KITCHEN, target: '_blank' };
  return (
    <div className={className}>
      <header>
        {richText?.title && (
          <TextNew.Script.MD
            color={Colors.BRAND}
            element={ElementTypes.H2}
            cid="homepage-secondary-showcase-title"
          >
            {richText.title}
          </TextNew.Script.MD>
        )}
      </header>

      {richText?.text && <RichTextBlock text={richText?.text} />}

      {video && <VideoBlock video={video?.video} showVideoThumbnail videoThumbnail={image} />}

      {!video && image && (
        <Link className="banner" {...buttonLink}>
          <ResponsiveImage
            {...image}
            ratio={350 / 199}
            alt="Food optimising delivered - Slimming World Kitchen"
          />
        </Link>
      )}

      {buttonText?.text && (
        <Button {...buttonLink} cid="cta">
          <TextNew.Script.XS element={ElementTypes.SPAN} color={Colors.PRIMARY_LIGHT}>
            {buttonText?.text}
          </TextNew.Script.XS>
        </Button>
      )}
    </div>
  );
};

HomepageSecondaryShowcase.propTypes = {
  image: ImagePropTypes,
  richText: PropTypes.object,
  buttonText: PropTypes.object,
  video: PropTypes.shape({
    video: PropTypes.shape({
      _type: PropTypes.oneOf([VideoType.YOUTUBE, VideoType.BRIGHTCOVE, VideoType.LEGACY]),
      videoId: PropTypes.string.isRequired,
      autohide: PropTypes.number,
      autoPlay: PropTypes.number,
      controls: PropTypes.number,
      videoThumbnail: ImagePropTypes,
      rel: PropTypes.number,
    }),
  }),
};

export default withFunctionalClassName(
  ComponentType.MOLECULE,
  'HomepageSecondaryShowcase',
)(HomepageSecondaryShowcase);
