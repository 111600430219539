import compose from 'redux/lib/compose';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { POSTCODE_IO, API_GOOGLE_GEOLOCATION } from 'common/src/app/data/externalApis';
import { syncValuesToRoute } from 'common/src/app/enhanced-redux-form/actions/formRouteSyncActions';
import {
  geocodeLocationGroupSearch,
  countyBasedGroupSearch,
  setGroupSearchLoading,
  GET_GROUP_SEARCH_RESULTS_CALL,
} from 'common/src/app/actions/resources/groupSearchActions';
import { toArray, toBitwise } from 'common/src/app/util/bitwiseUtils';
import FormNames from 'common/src/app/data/enum/FormNames';
import { withInitAction, prepareComponent } from '@mediamonks/react-redux-component-init';
import {
  API_STATIC_PUBLIC_NEAREST_GROUP_BULLETIN_V2,
  API_STATIC_PUBLIC_NEAREST_GROUP_SEARCH_RESULTS_V2,
} from 'common/src/app/data/apiStatics';
import { getStaticContent } from 'common/src/app/actions/resources/staticContentActions';
import { staticPageSelector } from 'common/src/app/selectors/publicSelectors';
import matchRoute from 'common/src/app/util/routeCheckUtils';
import Pages from 'common/src/app/data/enum/Pages';
import { getApiNoCache } from 'common/src/app/actions/externalApiActions';
import makeIsLoadingSelector from 'common/src/app/selectors/makeIsLoadingSelector';
import {
  textBasedGroupSearch,
  dropDownBasedGroupSearch,
  marketName,
} from 'common/src/app/config/market/market.configdefinitions';

// Search filter
import GroupSearchFieldNames from 'common/src/app/data/enum/FieldNames/GroupSearchFieldNames';

import {
  NearestGroupSearchDayFlags,
  NearestGroupSearchTimesFlags,
  NearestGroupSearchAccessibilityFlags,
} from 'common/src/app/data/enum/GroupSearchFilterFlags';

import PhysicalGroupsLanding from './components/organisms/PhysicalGroupsLanding';
import EmptySearchResults from './components/organisms/EmptySearchResults';
import GeocodeProviders from '../../../../../../data/enum/geocodeProviders';
import NearestGroupLanding from './NearestGroupLanding';

import getMarketRichTextContent from '../../../Utils/getMarketRichTextContent';

const EMPTY_ARRAY = [];

const haveSameProperties = (initialValues, values) =>
  ['days', 'times', 'venueAccessibility'].every(
    field =>
      initialValues?.[field] === values[field] ||
      (!initialValues?.[field] && values[field] === null),
  );

export const nearestGroupResultsSelector = state => state.view.nearestGroup.searchResults;

const mapStateToProps = state => {
  const isLoadingSearchSelector = makeIsLoadingSelector(GET_GROUP_SEARCH_RESULTS_CALL);
  const results = nearestGroupResultsSelector(state);
  const canSearchNearestGroup = matchRoute(Pages.UK_PUBLIC_NEAREST_GROUP_LANDING, state);

  let geoLocationFromSearchAction;

  if (state.config.environmentConfig.geocoding?.provider === GeocodeProviders.POSTCODEIO) {
    geoLocationFromSearchAction = state.externalApis?.[POSTCODE_IO]?.[0]?.geometry?.location;
  } else {
    geoLocationFromSearchAction =
      state.externalApis?.[API_GOOGLE_GEOLOCATION]?.[0]?.geometry?.location;
  }

  const query = state.routing.locationBeforeTransitions.query;

  return {
    geoLocationFromSearch: geoLocationFromSearchAction,
    currentPosition: {
      lat: state?.geoLocation?.lat,
      lng: state?.geoLocation?.lng,
    },
    results: results || EMPTY_ARRAY,
    isLoading: isLoadingSearchSelector(state) || state.view.nearestGroup?.isLoading,
    hasFormReset: state.view.nearestGroup.formReset,
    gridColumnCount: state.view.components.grid?.layout,
    canSearchNearestGroup,
    bulletinMessage: getMarketRichTextContent(
      marketName,
      staticPageSelector(state, API_STATIC_PUBLIC_NEAREST_GROUP_BULLETIN_V2),
    ),
    searchResultsMessage: getMarketRichTextContent(
      marketName,
      staticPageSelector(state, API_STATIC_PUBLIC_NEAREST_GROUP_SEARCH_RESULTS_V2),
    ),
    initialValues: query,
  };
};

const mapDispatchToProps = dispatch => ({
  updateUrl: values => dispatch(syncValuesToRoute(values, Object.values(GroupSearchFieldNames))),
});

const connected = connect(mapStateToProps, mapDispatchToProps);

const addInitAction = withInitAction(['location.query'], {
  prepared: ({ location: { query } = {} }, dispatch) => {
    const { days, times, venueAccessibility, query: rawPostcode, locationSearch } = query;

    const promises = [
      dispatch(getStaticContent(API_STATIC_PUBLIC_NEAREST_GROUP_BULLETIN_V2, true)).catch(
        () => null,
      ),
      dispatch(getStaticContent(API_STATIC_PUBLIC_NEAREST_GROUP_SEARCH_RESULTS_V2, true)).catch(
        () => null,
      ),
      dispatch(prepareComponent(PhysicalGroupsLanding)),
      dispatch(prepareComponent(EmptySearchResults)),
    ];

    const cheekyThunk = () => (_, getState) => getState();
    const geocodingProvider = dispatch(cheekyThunk()).config.environmentConfig.geocoding.provider;

    const { lat, lng } = dispatch(cheekyThunk())?.geoLocation || {};

    if (lat && lng && locationSearch) {
      return dispatch(geocodeLocationGroupSearch({ lat, lng, days, times, venueAccessibility }));
    } else if (textBasedGroupSearch && rawPostcode) {
      const geocodeProviderEnum =
        geocodingProvider === GeocodeProviders.POSTCODEIO ? POSTCODE_IO : API_GOOGLE_GEOLOCATION;

      promises.push(
        dispatch(getApiNoCache(geocodeProviderEnum, rawPostcode)).then(results => {
          let latLng;
          if (geocodeProviderEnum === POSTCODE_IO) {
            latLng = {
              lat: results.latitude,
              lng: results.longitude,
            };
          }
          if (geocodeProviderEnum === API_GOOGLE_GEOLOCATION && results.length > 0) {
            latLng = results[0].geometry.location;
          }
          return dispatch(
            geocodeLocationGroupSearch({ ...latLng, days, times, venueAccessibility, rawPostcode }),
          );
        }),
      );
    } else if (dropDownBasedGroupSearch && rawPostcode) {
      promises.push(
        dispatch(countyBasedGroupSearch(rawPostcode, marketName, days, times, venueAccessibility)),
      );
    }

    return Promise.all(promises).then(() => dispatch(setGroupSearchLoading(false)));
  },
  getPrepareKey: componentId => componentId,
});

const enhanced = withFormik({
  displayName: FormNames.PUBLIC_GROUP_SEARCH,
  enableReinitialize: true,
  mapPropsToValues: ({ location }) => {
    const query = location?.query;

    return {
      days: query?.days ? toArray(NearestGroupSearchDayFlags, query?.days) : [],
      times: query?.times ? toArray(NearestGroupSearchTimesFlags, query?.times) : [],
      venueAccessibility: query?.venueAccessibility
        ? toArray(NearestGroupSearchAccessibilityFlags, query?.venueAccessibility)
        : [],
    };
  },
  handleSubmit: (
    { days: daysArray, times: timesArray, venueAccessibility: venueAccessibilityArray },
    { setSubmitting, props },
  ) => {
    const values = {
      days: daysArray ? toBitwise(daysArray, NearestGroupSearchDayFlags) : [],
      times: timesArray ? toBitwise(timesArray, NearestGroupSearchTimesFlags) : [],
      venueAccessibility: venueAccessibilityArray
        ? toBitwise(venueAccessibilityArray, NearestGroupSearchAccessibilityFlags)
        : null,
    };

    if (haveSameProperties(props.initialValues, values)) {
      setSubmitting(false);
      return;
    }
    props.updateUrl(values);

    setSubmitting(false);
  },
});

export default compose(addInitAction, connected, enhanced)(NearestGroupLanding);
