import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import ComponentType from 'common/src/app/data/enum/ComponentType';
import HeroBarPublic from 'components/organisms/HeroBarPublic';
import Wrapper from 'components/atoms/Wrapper';
import TextNew, {
  Colors,
  ElementTypes,
  FontWeight,
  FontSizes,
  AccentColors,
} from 'components/atoms/TextNew';
import Loader from 'components/atoms/Loader';
import RichTextBlock from 'components/molecules/BlockContent/components/atoms/RichTextBlock';
import { withFunctionalClassName } from 'common/src/app/util/componentClassNameUtils';
import MasterTile from 'components/organisms/MasterTile';
import errorBoundary from 'components/hoc/ErrorBoundary';
import FiveStarReview from 'components/molecules/FiveStarReview';
import { DeviceState } from 'common/src/app/data/MediaQueries';
import withDeviceState from 'common/src/app/util/device-state/withDeviceState';

import RegistrationCTAWithOptionalRoundelAndTitle from '../../molecules/RegistrationCTAWithOptionalRoundelAndTitle';
import OnlineCarousel from '../../molecules/OnlineCarousel';

import './slimming-world-app.scss';

const SlimmingWorldApp = (
  { slimmingWorldAppData, isInitializing, deviceState },
  context,
  className,
) => {
  if (isInitializing)
    return (
      <div className={className}>
        <Loader />
      </div>
    );

  return (
    <div className={className}>
      <header>
        <HeroBarPublic image={slimmingWorldAppData.heroImage}>
          <TextNew.Serif.SM cid="hero-bar-public-serif-heading" weight={FontWeight.BOLD}>
            {slimmingWorldAppData.subtitle}
          </TextNew.Serif.SM>
          <TextNew.Script.MD
            cid="hero-bar-public-script-heading"
            color={Colors.BRAND}
            element={ElementTypes.H1}
          >
            {slimmingWorldAppData.title}
          </TextNew.Script.MD>
          <TextNew.Serif.SM cid="hero-bar-public-introduction" element={ElementTypes.H2}>
            {slimmingWorldAppData.introductionTitle}
          </TextNew.Serif.SM>
        </HeroBarPublic>
      </header>

      <RegistrationCTAWithOptionalRoundelAndTitle
        introText={slimmingWorldAppData.introduction}
        buttonLeft={{
          link: slimmingWorldAppData.buttons[0].buttonLink,
          label: slimmingWorldAppData.buttons[0].buttonLabel,
        }}
        buttonRight={{
          link: slimmingWorldAppData.buttons[1].buttonLink,
          label: slimmingWorldAppData.buttons[1].buttonLabel,
        }}
      />

      <section className="carousel">
        <OnlineCarousel
          appPromoTitle={slimmingWorldAppData.appPromoTitle}
          appPromos={slimmingWorldAppData.appPromos}
        />
      </section>

      <Wrapper.MD>
        <TextNew.Script.SM
          size={deviceState > DeviceState.LG ? FontSizes.MD : FontSizes.SM}
          color={Colors.BRAND}
          cid="feature-card-block-title"
        >
          {slimmingWorldAppData.appFeaturesTitle}
        </TextNew.Script.SM>

        <div className="feature-cards-block">
          {slimmingWorldAppData.appFeatureCards.map(featureCard => (
            <div className="feature-card" key={featureCard.image.id}>
              <MasterTile
                id={featureCard.image.id}
                customDescription={
                  <>
                    <TextNew.Serif.SM
                      color={AccentColors.ACCENT_PURPLE}
                      weight={FontWeight.BOLD}
                      cid="card-title"
                    >
                      {featureCard.title}
                    </TextNew.Serif.SM>
                    <RichTextBlock cid="content" text={featureCard.description} />
                  </>
                }
                image={featureCard.image}
              />
            </div>
          ))}
        </div>
      </Wrapper.MD>

      <Wrapper element={ElementTypes.ASIDE} width="md" padding="sm">
        <FiveStarReview
          reviewTitle={slimmingWorldAppData.reviewTitle}
          reviewBody={slimmingWorldAppData.reviewText}
        />
      </Wrapper>
    </div>
  );
};

SlimmingWorldApp.propTypes = {
  isInitializing: PropTypes.bool,
  slimmingWorldAppData: PropTypes.object,
  deviceState: PropTypes.number,
};

export default compose(
  errorBoundary({ wholePageError: true }),
  withDeviceState(),
  withFunctionalClassName(ComponentType.PAGE, 'SlimmingWorldApp'),
)(SlimmingWorldApp);
